<template>
  <v-container
    style="max-width: 1200px"
    class="justify-center d-flex flex-column fill-width"
    v-if="currentUser"
    >
    <v-card
      outlined
      rounded="lg"
      >
      <v-card-title
        class="justify-center"
        >
        Ferias QR
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>

        <v-row
          >
          <v-col
            md="4"
            cols="12"
            >
            <list
              @select="updateSelected"
              ></list>
          </v-col>

          <v-col
            md="8"
            cols="12"
            >
            <detail
              :id="selected"
              v-if="selected"
              ></detail>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters} from 'vuex'

const MainHeader = () => import(/* webpackPrefetch: true */ "@/components/admin/shared/Header");
const List = () => import(/* webpackPrefetch: true */ "@/components/partners_fans/fairs/List");
const Detail = () => import(/* webpackPrefetch: true */ "@/components/partners_fans/fairs/Detail");

export default {
  data: () => ({
    selected: null
  }),

  computed: {
    ...mapGetters(['currentUser'])
  },

  methods: {
    updateSelected (id) {
      this.selected = id
    }
  },

  components: {
    MainHeader,
    List,
    Detail,
  }
}
</script>
